import CreateObjective from './create';
import EditObjective from './edit';
import ObjectivesList from './list';
import ObjectiveShow from './show';

export default {
    create: CreateObjective,
    edit: EditObjective,
    list: ObjectivesList,
    show: ObjectiveShow,
};
