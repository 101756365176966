import CreateReportCategory from './create';
import EditReportCategory from './edit';
import ReportCategoryList from './list';
import ReportCategoryShow from './show';

export default {
    edit: EditReportCategory,
    create: CreateReportCategory,
    list: ReportCategoryList,
    show: ReportCategoryShow,
};
