import CreateQuestion from './create';
import EditQuestion from './edit';
import QuestionList from './list';
import QuestionShow from './show';

export default {
    edit: EditQuestion,
    create: CreateQuestion,
    list: QuestionList,
    show: QuestionShow,
};
