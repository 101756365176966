import CreateInterestCategory from './create';
import EditInterestCategory from './edit';
import InterestCategoryList from './list';
import InterestCategoryShow from './show';

export default {
    create: CreateInterestCategory,
    edit: EditInterestCategory,
    list: InterestCategoryList,
    show: InterestCategoryShow,
};
